import resolveConfig from 'tailwindcss/resolveConfig';
import { Config } from 'tailwindcss';
// @ts-ignore
import tailwindConfig from '../tailwind.config.js';
import { VNode } from 'vue';

export function getTwConfig(): Config {
    return resolveConfig(tailwindConfig) as Config;
}

export function filterItems(child: VNode) {
    return child.type && typeof child.type === 'object';
}

const setState = (state: string[]) => {
    window.history.replaceState({}, null, `#${state.join('&')}`);
};

const getState = (): string[] => {
    return location.hash
        .replace(/^#|\W$/, '')
        .split('&')
        .filter(x => x.length);
};

export const addToState = (key: string, value: string) => {
    const state = getState();
    const index = state.findIndex(x => x.split(':').length > 0 && x.split(':')[0] === key);
    if (index < 0) {
        state.push(`${key}:${value}`);
    } else {
        const values = state[index].split(':')[1].split(',');
        if (!values.includes(value)) {
            values.push(value);
            state[index] = `${key}:${values.join(',')}`;
        }
    }
    setState(state);
};

export const removeFromState = (key: string, value = '') => {
    const state = getState();
    const index = state.findIndex(x => x.split(':').length > 0 && x.split(':')[0] === key);
    if (index >= 0) {
        if (value.length) {
            const values = state[index].replace(/[\w-]+:/, '').split(',');
            const valueIndex = values.findIndex(x => x === value);
            if (valueIndex >= 0) {
                values.splice(valueIndex, 1);
                if (values.length > 0) {
                    state[index] = `${key}:${values.join(',')}`;
                } else {
                    state.splice(index, 1);
                }
            }
        } else {
            state.splice(index, 1);
        }
        setState(state);
    }
};

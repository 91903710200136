const plugin = require('tailwindcss/plugin');

module.exports = {
    content: [
        './templates/**/*.ftl',
        './includes/templates/*.ftl',
        './lib/*.ftl',
        './ts/**/*.vue',
        './ts/**/*.ts'
    ],
    corePlugins: {
        container: false
    },
    theme: {
        animation: {
            spin: 'spin 30s linear infinite'
        },
        aspectRatio: {
            auto: 'auto',
            portrait: '2/3',
            square: '1/1',
            landscape: '16/9'
        },
        colors: {
            current: 'currentColor',
            transparent: 'transparent',
            red: '#a31001',
            white: '#ffffff',
            black: {
                DEFAULT: '#000000',
                900: '#181819',
                500: '#393c47'
            },
            gray: {
                DEFAULT: '#f7f7f7',
                100: '#eaeaea',
                300: '#a3a3a3',
                400: '#747474'
            },
            sun: {
                DEFAULT: '#fdfcab',
                light: '#fff0c6',
                neon: '#effd2a'
            },
            ginger: {
                100: '#ede8e8',
                200: '#e6dbce',
                300: '#d3c9bc',
                DEFAULT: '#b08868'
            },
            green: {
                DEFAULT: '#829788',
                700: '#02640f'
            },
            moon: {
                DEFAULT: '#212f3f',
                500: '#0C1724'
            },
            purple: {
                100: '#d4bede',
                200: '#d2a7ee',
                300: '#460b5a',
                DEFAULT: '#460b5a'
            }
        },
        fontFamily: {
            sans: ['Assistant', 'sans-serif'],
            serif: ['Volta', 'serif']
        },
        fontSize: {
            'xs': ['0.75rem', '1.5rem'], // 12px
            'sm': ['0.875rem', '1.125rem'], // 14px
            'base': ['1rem', '1.3125rem'], // 16px
            'md': ['1.125rem', '1.4375rem'], // 18px
            'lg': ['1.25rem', '1.75rem'], // 20px
            'xl': ['1.5rem', '2rem'], // 24px
            '2xl': ['2rem', '2.275rem'], // 32px
            '3xl': ['3.5rem', '3.875rem'], // 56px
            '4xl': ['3.75rem', '4rem'], // 60px
            '5xl': ['4rem', '4.125rem'], // 64px
            '6xl': ['4.5rem', '5rem'], // 72px
            'big': ['6.25rem', '6.25rem'], // 100px (volta),
            'bigger': ['12.5rem', '12.5rem'], // 200px (volta),
            'huge': ['20rem', '20rem'] // 400px (volta),
        },
        extend: {
            aspectRatio: {
                portrait: '2/3'
            },
            backgroundImage: {
                'quote': "url('../assets/img/bg-sun.svg')",
                'google-maps-fallback': "url('../assets/img/google-maps-fallback.jpg')"
            },
            borderRadius: {
                '4xl': '5rem',
                '5xl': '12.5rem'
            },
            fill: {
                current: 'currentColor'
            },
            height: {
                120: '30rem',
                144: '36rem',
                160: '40rem',
                168: '42rem',
                200: '50rem'
            },
            minHeight: {
                140: '35rem',
                200: '50rem'
            },
            maxHeight: {
                36: '9rem',
                44: '11rem'
            },
            maxWidth: {
                xl: '1368px'
            },
            padding: {
                '1/1': '100%',
                '18': '4.5rem'
            },
            scale: {
                30: '0.3'
            },
            screens: {
                'xl': '1368px',
                '3xl': '1830px'
            },
            strokeWidth: {
                3: '3px'
            },
            spacing: {
                18: '4.5rem'
            },
            typography: {
                DEFAULT: {
                    css: {
                        'color': 'inherit',
                        '--tw-prose-td-borders': 'transparent',
                        'line-height': '1.5rem'
                    }
                },
                links: {
                    css: {
                        'color': 'inherit',
                        '--tw-prose-td-borders': 'transparent',
                        'a': {
                            textDecoration: 'underline'
                        }
                    }
                }
            },
            width: {
                xl: '1368px'
            }
        }
    },
    plugins: [
        require('@tailwindcss/line-clamp'),
        require('@tailwindcss/typography'),
        require('tailwindcss-labeled-groups')(['1']),
        plugin(({ addVariant }) => {
            addVariant('active-slide', ['.slide-active &', '&.slide-active']);
            addVariant('next-slide', ['.slide-next &', '&.slide-next']);
            addVariant('prev-slide', ['.slide-prev &', '&.slide-prev']);
        })
    ]
};

import { computed, onMounted, onUnmounted, provide, ref } from 'vue';
import { NavItem } from '@/types';

/**
 * generic navigation-related logic, commonly used in navigation components
 * independent of styling
 */
export function useNavigation(editMode = false) {
    const items = ref<NavItem[]>([]);
    // stored the currently open nav item as a series of indices,
    // so we can go back by just removing the last one.
    const path = ref<number[]>([]);
    const isTop = ref(true);
    const isOpen = ref(false);
    const isClosing = ref(false);
    const showNavi = ref(true);
    let lastScrollPos = 0;

    // returns the active item based on `path`
    const currentItem = computed<NavItem>(() => {
        let item = { title: '', depth: 0, items: items.value } as NavItem;
        path.value.forEach(x => {
            if (item.items && item.items.length > x) {
                item = item.items[x];
            }
        });
        return item;
    });

    // we can go back as long as we're at least 1 level deep
    const hasBack = computed(() => !!path.value.length);

    function registerMainNavItem(item: NavItem) {
        items.value.push(item);
    }

    function navigate(index: number) {
        path.value.push(index);
    }

    function back() {
        path.value.pop();
    }

    function toggle() {
        isOpen.value = !isOpen.value;
        if (!isOpen.value) {
            // reset menu on close
            path.value = [];
        }
    }

    function onScroll(event: Event) {
        if (editMode) {
            return;
        }

        if (isOpen.value) {
            event.preventDefault();
            return;
        }
        const scrollPos = Math.max(document.scrollingElement.scrollTop, 0);
        isTop.value = scrollPos === 0;
        showNavi.value = scrollPos <= lastScrollPos;
        lastScrollPos = scrollPos;
        isClosing.value = false;
    }

    provide('registerMainNavItem', registerMainNavItem);

    onMounted(() => {
        document.addEventListener('scroll', onScroll, { passive: false });
    });

    onUnmounted(() => {
        document.removeEventListener('scroll', onScroll);
    });

    return { currentItem, hasBack, isTop, isOpen, showNavi, navigate, back, toggle };
}

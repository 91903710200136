const services: { [key: string]: string } = {
    klaro: 'klaro',
    recaptcha: 'recaptcha',
    googleMaps: 'google-maps',
    youtube: 'youtube',
    tagManager: 'tag-manager',
    googleAnalytics: 'google-analytics',
    hotjar: 'hotjar'
};

export default services;

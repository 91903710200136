import { onMounted } from 'vue';

/**
 * Observe hash changes in the url, smooth scroll to anchor links and preserve data in the hash
 */
export function useHash(callback: (el: Element, hash: string) => void) {
    function onHashchange() {
        // manually scroll to anchor links, since hash can also contain other state info
        if (location.hash && location.hash.length) {
            location.href = location.hash;
            const hash = location.hash
                .replace(/^#/, '')
                .split('&')
                .filter(x => !x.includes(':'));
            if (hash.length > 0 && hash[0]) {
                const el = document.getElementById(hash[0]);
                if (el) {
                    setTimeout(() => {
                        callback(el, location.hash);
                    }, 500);
                }
            }
        }
    }

    onMounted(() => {
        window.addEventListener('hashchange', this.onHashchange);
        onHashchange();
    });
}

import { DirectiveBinding } from 'vue';

async function sendRequest(url: string, data: FormData): Promise<boolean> {
    try {
        const headers = new Headers();
        headers.append('Content-Type', 'multipart/form-data');
        const res = await fetch(url, {
            method: 'post',
            body: data
        });
        return res.status === 200;
    } catch (err) {
        console.error(err);
        return false;
    }
}

async function onSubmit(event: Event, id: string): Promise<boolean> {
    // prevent form submit which triggers page reload
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const fd: FormData = new FormData(form);
    fd.append('formId', id);

    try {
        if (form.checkValidity()) {
            console.log('form valid, sending request');
            return await sendRequest(form.action, fd);
        } else {
            console.log('form invalid');
            for (let i = 0; i < form.elements.length; i++) {
                if (!(form.elements[i] as HTMLFormElement).reportValidity()) {
                    break; // trigger html validation messages
                }
            }
            return false;
        }
    } catch (err) {
        // TODO: handle exception
        console.error(err);
        return false;
    }
}

export default {
    mounted(element: HTMLElement, binding: DirectiveBinding) {
        if (element.tagName.toLowerCase() !== 'form' || !binding.value) {
            return;
        }
        element.onsubmit = ev => onSubmit(ev, binding.value);
    }
};

/* eslint-disable dot-notation */
import { createApp } from 'vue';
import VueSmoothScroll from 'vue3-smooth-scroll';
import { useHash } from '@/composables/useHash';
import Rize from '@/rize';

const app = createApp({
    mounted() {
        useHash((el: Element, hash: string) => {
            this.$smoothScroll({
                scrollTo: el,
                updateHistory: false,
                hash: hash
            });
        });
        window.addEventListener('resize', this.onResize);
        this.onResize();
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            document.documentElement.style.setProperty(
                '--scrollbar-width',
                window.innerWidth - document.documentElement.clientWidth + 'px'
            );
        }
    }
});

/* Plugins */
app.use(VueSmoothScroll);
app.use(Rize);

// IMPORTANT FOR GREENBARS
app.config.compilerOptions.comments = true;

/* mount app AFTER all global components are registered */
app.mount('#app');

declare module 'vue' {
    interface ComponentCustomProperties {
        $contextPath: string;
        $resourcePath: string;
        $siteName: string;
        $lang: string;
        $prototype: string;
        $editMode: boolean;
    }
}

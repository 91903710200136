/* eslint-disable dot-notation */
import { App } from 'vue';
import Animate from '@/directives/animate';
import LzSlider from '@/components/base/LzSlider.vue';
import LzAccordion from '@/components/base/LzAccordion.vue';
import LzGoogleMaps from '@/components/maps/LzGoogleMaps.vue';
import MainNavigation from '@/components/navigation/MainNavigation.vue';
import NavItem from '@/components/navigation/NavItem.vue';

import LzDropdown from '@/components/base/LzDropdown.vue';
import LzDropdownItem from '@/components/base/LzDropdownItem.vue';
import LzOverview from '@/components/overview/LzOverview.vue';
import OverviewFilter from '@/components/overview/OverviewFilter.vue';
import OverviewCategory from '@/components/overview/OverviewCategory.vue';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';
import CookieManager from '@/components/cookies/CookieManager.vue';
import LzYoutube from '@/components/video/LzYoutube.vue';
import LzCookieButton from '@/components/cookies/LzCookieButton.vue';
import LzIcon from '@/components/base/LzIcon.vue';
import AsyncForm from '@/directives/async-form';
import CartButton from '@/components/shop/CartButton.vue';

export default {
    install(app: App) {
        /* plugins */
        // ...

        /* directives */
        app.directive('animate', Animate);
        app.directive('async-form', AsyncForm);

        /* components */
        app.component('collapse-transition', CollapseTransition);
        app.component('lz-slider', LzSlider);
        app.component('lz-accordion', LzAccordion);
        app.component('lz-google-maps', LzGoogleMaps);
        app.component('lz-nav', MainNavigation);
        app.component('lz-nav-item', NavItem);
        app.component('lz-dropdown', LzDropdown);
        app.component('lz-dropdown-item', LzDropdownItem);
        app.component('lz-overview', LzOverview);
        app.component('lz-filter', OverviewFilter);
        app.component('lz-category', OverviewCategory);
        app.component('lz-cookie-manager', CookieManager);
        app.component('lz-youtube', LzYoutube);
        app.component('lz-cookie-button', LzCookieButton);
        app.component('lz-icon', LzIcon);
        app.component('lz-cart-button', CartButton);

        /**
         * global properties
         */
        app.config.globalProperties.$contextPath = window['contextPath'];
        app.config.globalProperties.$resourcePath = window['resourcePath'];
        app.config.globalProperties.$siteName = window['siteName'];
        app.config.globalProperties.$lang = window['lang'];
        app.config.globalProperties.$prototype = window['prototype'];
        app.config.globalProperties.$editMode = window['editMode'];
        // also provide them, so we can use them in composition api components with `inject`
        app.provide('contextPath', window['contextPath']);
        app.provide('resourcePath', window['resourcePath']);
        app.provide('siteName', window['siteName']);
        app.provide('lang', window['lang']);
        app.provide('prototype', window['prototype']);
        app.provide('editMode', window['editMode']);
    }
};

/*
 *
 * Directive to animate an HTML element when it is in view
 *
 * Usage:
 *
 * 1. set 'v-animate' directive on element, and it will take the all default values
 *    and show an 'swipe-left' animation
 *
 * 2. to configure another animation style, set a name to the 'v-animate' directive
 *    like this 'v-animate:swipe-right' or 'v-animate:slide'
 *
 * 3. to configure the transform values, set the values to the 'v-animate' directive
 *    like this 'v-animate:slide="{ delay: 0, duration: 1000, translateY: '-100px', threshold: 0.05}'
 *
 * 4. to configure the animation on specific device (desktop or mobile),
 *    set a modifier to the 'v-animate' directive
 *    like this 'v-animate:slide.desktop.mobile'
 *
 */

import { DirectiveBinding } from 'vue';
import { getTwConfig } from '@/utils';

export default {
    mounted(el: HTMLElement, binding: DirectiveBinding) {
        // eslint-disable-next-line dot-notation
        if (!window['editMode']) {
            const twConfig = getTwConfig();
            const _name = binding.arg ? binding.arg : 'default';
            const _onload = binding.modifiers.load;

            // default values
            const _delay = binding.value && binding.value.delay ? binding.value.delay : 0;
            const _threshold = binding.value && binding.value.threshold ? binding.value.threshold : 0.005;
            const _duration = binding.value && binding.value.duration ? binding.value.duration : 1000;

            if(_name === 'height-top'){
                el.style.transition = `clip-path ${_duration}ms ease-in-out ${_delay}ms`;
                el.style.clipPath = `inset(0% 0% 99% 0%)`;
            }
            else if(_name === 'width-right'){
                el.style.transition = `clip-path ${_duration}ms ease-in-out ${_delay}ms`;
                el.style.clipPath = `inset(0% 99% 0% 0%)`;
            }
            else if(_name === 'show') {
                el.style.opacity = '0';
                el.style.transition = `opacity ${_duration}ms ease-in-out ${_delay}ms`;
            }

            if (_onload) {
                setTimeout(animate, 500);
            } else {
                // eslint-disable-next-line no-case-declarations
                const observer = new IntersectionObserver(callback, {
                    root: null,
                    rootMargin: '0px',
                    threshold: _threshold
                });
                observer.observe(el);
            }


            function callback(entries: IntersectionObserverEntry[]) {
                const ratio = 0.005;
                Array.from(entries).forEach(entry => {
                    if (
                        (window.innerWidth >= breakpointSize('lg') &&
                            entry.intersectionRatio > ratio) ||
                        (window.innerWidth < breakpointSize('lg') &&
                            entry.intersectionRatio > ratio &&
                            entry.intersectionRatio !== 1)
                    ) {
                        animate();
                    }
                });
            }

            function breakpointSize(name: string): number {
                if (Object.prototype.hasOwnProperty.call(twConfig.theme.screens, name)) {
                    return parseInt(twConfig.theme.screens[name]);
                }
                return -1;
            }

            function animate() {
                el.style.clipPath = 'inset(0% 0% 0% 0%)';
                el.style.opacity = '1';
            }
        }
    }
};
